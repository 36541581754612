import * as React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import _, { map } from 'lodash'

import { containerStyle } from '../../Base'
import {
  discountMoreDetails,
  mooncakeDiscountListData,
} from '../../../utils/mooncakes'
import BannerHeading from '../BannerHeading'
import { BotRight } from '../SubBgImg'
import useMediaQuery from '../../../hooks/useMediaQuery'

const TextStyle = tw`text-savor-cake-primary-green-avocado-200 text-base sm:text-lg md:text-xl`
const DiscountDetails = () => {
  return (
    <div
      tw="w-full
      md:rounded-5xl px-1 sm:px-8 lg:px-0
      "
    >
      <div tw="flex flex-wrap justify-center gap-2 sm:gap-3 md:gap-6 lg:gap-4 mb-5 text-center">
        {_.map(mooncakeDiscountListData, (item, index) => {
          return (
            <div tw="relative w-2/5 md:w-1/3 lg:w-[23%]" key={index}>
              <div tw="">
                <StaticImage
                  src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/discountFr.png"
                  alt="background image"
                  placeholder="blurred"
                />
              </div>
              <div tw="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center md:px-1 md:pt-4 w-full pb-6 md:pb-12">
                <div css={[TextStyle, tw`md:text-xl font-semibold`]}>
                  Giảm giá
                </div>
                <div tw="text-sc_red-100 text-2xl md:text-4xl lg:text-5xl font-extrabold">
                  {item.discountAmount}
                </div>
                <div tw="font-bold mx-auto w-2/3 border-t border-1 border-dashed border-savor-cake-primary-green-avocado-200 my-1"></div>
                <div css={[TextStyle, tw`font-medium`]}>Khi mua từ</div>
                <div css={[TextStyle, tw`font-bold`]}>
                  {item.quantityToDiscount} bánh
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <ul tw="list-disc mx-auto px-6 sm:px-10 md:pl-20 lg:pl-10 pl-10 text-justify">
        {map(discountMoreDetails, (item, index) => {
          return (
            <li
              tw="text-savor-cake-primary-green-avocado-200 text-base sm:text-lg md:text-xl font-light leading-6 text-left"
              key={index}
            >
              {item.content}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
export default function DiscountSection() {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const sm = useMediaQuery('(min-width: 640px)')
  let style = { bottom: -5, width: '10vw' }
  if (sm) {
    style = { bottom: '-6%', width: '10vw' }
  }
  if (isDesktop) {
    style = { bottom: -20, width: '8vw' }
  }
  return (
    <div
      id="discount-moon"
      tw="font-savor-cake-primary-lexend-deca mx-auto bg-sc_yellow-200 pb-14 relative"
    >
      <BotRight customStyles={style} />
      <BannerHeading textVariant={'moon24'} groupTitle={'Chính sách giá sỉ'} />
      <div css={containerStyle} tw="px-0">
        <DiscountDetails />
      </div>
    </div>
  )
}
