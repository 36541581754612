import React, { forwardRef } from 'react'
import 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'

import { SectionContainer } from '../BanhSinhNhatPage/Base'
import useMediaQuery from '../../hooks/useMediaQuery'

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  overflow: 'visible',
  aspectRatio: 'auto',
}
const StarsBg = () => {
  return (
    <div tw="overflow-hidden">
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/stars-bg.png"
        alt="stars background"
        placeholder="blurred"
        layout="fullWidth"
        style={{ ...AbsoluteStyle, width: '100%', top: 0 }}
      />
    </div>
  )
}
const Mountain = ({ isMediumScreen }) => {
  return (
    <div tw="overflow-hidden">
      {isMediumScreen ? (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/mountains.png"
          alt="mountain background"
          placeholder="blurred"
          layout="fullWidth"
          style={{ ...AbsoluteStyle, width: '100%', bottom: 0 }}
        />
      ) : (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/mountains-mobile.png"
          alt="mountain mobile"
          placeholder="blurred"
          layout="fullWidth"
          style={{ ...AbsoluteStyle, width: '100%', bottom: 0 }}
        />
      )}
    </div>
  )
}
// const MoonTop = ({ isMediumScreen }) => {
//   let style = { width: '40vw', top: '-4rem' }
//   if (isMediumScreen) {
//     style = { width: '25vw', top: '-2rem' }
//   }
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/moon-left.png"
//       alt="moon"
//       placeholder="blurred"
//       layout="fullWidth"
//       style={{ ...AbsoluteStyle, left: '-0.2rem', ...style }}
//     />
//   )
// }
// const BirdRight = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/bird-right.png"
//       alt="bird"
//       placeholder="blurred"
//       layout="fullWidth"
//       style={{ ...AbsoluteStyle, width: '20vw', right: 0, top: '10rem' }}
//     />
//   )
// }
// const CloudLeft = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/cloud-left.png"
//       alt="cloud"
//       placeholder="blurred"
//       layout="fullWidth"
//       style={{ ...AbsoluteStyle, width: '20vw', left: 0, top: '60rem' }}
//     />
//   )
// }
// const CloudRight = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/mooncake-group/cloud-right.png"
//       alt="cloud"
//       placeholder="blurred"
//       layout="fullWidth"
//       style={{ ...AbsoluteStyle, width: '20vw', right: 0, top: '90rem' }}
//     />
//   )
// }

export default forwardRef(({ children }, ref) => {
  const isMediumScreen = useMediaQuery('(min-width: 768px)')
  return (
    <SectionContainer ref={ref} background="#54884F" tw="md:overflow-hidden">
      <StarsBg />
      <Mountain isMediumScreen={isMediumScreen} />
      {isMediumScreen && (
        <>
          {/* <BirdRight /> */}
          {/* <CloudLeft /> */}
          {/* <CloudRight /> */}
        </>
      )}
      {children}
    </SectionContainer>
  )
})
