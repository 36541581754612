import React from 'react'
import 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
// import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'react-scroll'
import { motion } from 'framer-motion'

import * as styles from '../../assets/scss/intro-section-landing-btt.module.scss'
import { containerStyle } from '../Base'
import { buttonVariants } from '../../utils/motion'
import dayjs from 'dayjs'
import { Left1, TopRight } from './SubBgImg'
import useMediaQuery from '../../hooks/useMediaQuery'

export default function IntroSection() {
  //   const data = useStaticQuery(graphql`
  //     {
  //       allFile(
  //         filter: {
  //           extension: { in: ["png", "jpg", "jpeg"] }
  //           relativePath: { regex: "/intro-section/" }
  //         }
  //         sort: { fields: [name], order: ASC }
  //       ) {
  //         edges {
  //           node {
  //             publicURL
  //             name
  //             childImageSharp {
  //               gatsbyImageData(width: 800, height: 800)
  //             }
  //           }
  //         }
  //       }
  //     }
  //   `)
  const sm = useMediaQuery('(min-width: 640px)')
  const desktop = useMediaQuery('(min-width: 1024px)')
  let topRightStyle = { width: '60px', top: 20 }
  let leftStyte = { width: '60px', bottom: 50, top: null }
  if (sm) {
    topRightStyle = { ...topRightStyle, width: '70px' }
    leftStyte = { ...leftStyte, bottom: 15, width: '70px' }
  }
  if (desktop) {
    topRightStyle = { ...topRightStyle, width: '80px' }
    leftStyte = {
      display: 'none',
    }
  }
  return (
    <div tw="bg-sc_yellow-200 relative">
      <TopRight customStyles={topRightStyle} />
      <div
        css={containerStyle}
        tw="
        lg:max-w-screen-xl! px-6! md:px-10! xl:px-16! py-10 md:pt-12
        font-savor-cake-primary-lexend-deca flex flex-col lg:flex-row relative z-10"
      >
        <div
          tw="
            order-first lg:order-last
            text-savor-cake-primary-green-avocado-200
            lg:w-1/2 lg:px-12 lg:py-5 mb-5 lg:mb-0"
        >
          <div tw="flex gap-4 mb-3 lg:mb-5">
            <div
              tw="text-lg md:text-xl
              font-medium"
            >
              Trung thu {dayjs().format('YYYY')}
            </div>
            <div
              tw="block w-22 h-[1px]
              self-end mb-2
              bg-savor-cake-primary-green-avocado-200
              "
            ></div>
          </div>
          <div className="w-11 h-5 bg-savor-cake-primary-green-avocado-200"></div>
          <div
            tw="
            font-bold font-barlow-sans-serif
            text-4xl mb-3 md:text-5xl uppercase"
          >
            giới thiệu
          </div>
          <p
            tw="leading-normal text-justify m-0 pb-4 font-normal
            text-base md:text-lg"
            style={{ lineHeight: '1.8' }}
          >
            Năm nay, Savor Cake tiếp tục cho ra mắt 20 vị bánh Trung thu nhân
            handmade. Chiếc bánh handmade sẽ có độ ngọt vừa phải (nỗi lo lớn
            nhất của người "hâm mộ" bánh trung thu), rất ngon vì nguyên liệu
            được lựa kỹ theo khẩu vị, xu hướng và đặc biệt là không sử dụng chất
            bảo quản. Cùng với đó chúng tớ cũng ra mắt 5 set hộp bánh cực xinh
            với mức giá cực ưu đãi phù hợp để biếu tặng. Savor Cake vẫn tiếp tục
            nhận order bánh Trung Thu Handmade đảm bảo chất lượng nha!
          </p>
          <motion.div variants={buttonVariants} whileHover="hover" tw="">
            <Link
              smooth={true}
              to="mooncake-collection"
              href="#mooncake-collection"
            >
              <div
                tw="relative bg-savor-cake-primary-green-avocado-200
                box-border rounded-md py-4 row-start-4
                lg:col-span-3 lg:row-span-1 lg:col-start-2
                mx-4 md:mx-28 lg:mx-10
                flex justify-center items-center"
                // href="https://m.me/savor.vn"
                // target="_blank"
                // rel="noreferrer noopener"
              >
                <span
                  tw="text-xl font-barlow-sans-serif text-savor-cake-primary-beige-200 no-underline hover:text-savor-cake-primary-beige-300
                xl:text-2xl
                font-bold z-10"
                >
                  ĐẶT HÀNG NGAY
                </span>
              </div>
            </Link>
          </motion.div>
        </div>
        <div
          tw="
          order-last lg:order-first
          w-full lg:w-1/2 my-auto"
        >
          <IntroSlider
            data={
              // data
              {}
            }
          />
        </div>
      </div>
      <Left1 customStyles={leftStyte} />
    </div>
  )
}

const IntroSlider = ({ data, nodeName }) => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: styles.intro,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
  }

  //   nodeName = nodeName || 'allFile'
  //   let dataForMap = data ? data[nodeName]?.edges : []
  //   if (nodeName === 'allS3Object') {
  //     dataForMap = dataForMap.filter(({ node }) => node.localFile !== null)
  //   }

  const dataForMap = [
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/products/banh-trung-thu/set-4-hop-bach-lien/bach-lien-busi-gift.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-4-hop-bach-lien.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-4-hop-hoa-thu.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-2-hop-hoa-thu.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-2-hop-nhat-ban.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-6-banh-nho-75g.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/products/banh-trung-thu/2024/set-4-cao-cap-kim-long/Kim%20long%204.1.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/products/banh-trung-thu/2024/banh-le-binh-dan/full-banh-le-thuong-cut.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
    // {
    //   image: (
    //     <StaticImage
    //       src="https://s3.kstorage.vn/savor-web/src/images/products/banh-trung-thu/2024/banh-nuong-cao-cap-2024/Banh-cao-cap-le-cut.jpg"
    //       alt="mooncake savor cake"
    //       placeholder="blurred"
    //     />
    //   ),
    // },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/products/banh-trung-thu/2024/banh-nuong-cao-cap-2024/Banh-cao-cap-le.jpg"
          alt="mooncake savor cake"
          placeholder="blurred"
        />
      ),
    },
  ]

  return (
    <div tw="">
      <Slider {...settings}>
        {dataForMap.map((file, index) => {
          //   let imagePath = file.node.childImageSharp
          //   let fileName = file.node.name
          //   if (nodeName === 'allS3Object') {
          //     imagePath = file.node.localFile.childrenImageSharp[0]
          //     fileName = file.node.localFile.name
          //   }
          //   const image = getImage(imagePath)
          return (
            <div key={index} tw="align-middle rounded-md!">
              {file.image}
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
