import 'twin.macro'

import * as React from 'react'
import { siteUrl } from '../utils/constants'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import HeroSection from '../components/BanhTrungThuPage/2024/HeroSection'
import FeedbackSection from '../components/BanhTrungThuPage/FeedbackSection'
import Footer from '../components/Base/Footer'
import DiscountSection from '../components/BanhTrungThuPage/2024/DiscountSection'
import IntroSection from '../components/BanhTrungThuPage/IntroSection'
import SEOComponent from '../components/SEOComponent'
import ToastContainer from '../components/ToastContainer'
import CartIcon from '../components/Base/CartIcon'
import ModalViewProduct from '../components/Base/ProductGroup/ModalViewProduct'
import MooncakeMenu from '../components/BanhTrungThuPage/2024/MooncakeMenu'
import CakeSetMenu from '../components/BanhTrungThuPage/2024/CakeSets/CakeSetMenu'
import AgentAndCollab from '../components/BanhTrungThuPage/2024/AgentAndCollab'
import BusinessFeats from '../components/BanhTrungThuPage/2024/BusinessFeats'

export default function BanhTrungThuPage() {
  return (
    <TailwindLayout style={{ overflow: 'hidden', position: 'relative' }}>
      <ToastContainer />
      <CartIcon />
      <ModalViewProduct nodeName={''} />
      <HeroSection />
      <IntroSection />
      {/* <FeatureSection /> */}
      <AgentAndCollab />
      <MooncakeMenu />
      <DiscountSection />
      <CakeSetMenu />
      <BusinessFeats />
      <FeedbackSection />
      <Footer bgColor="#54884F" />
      <SEOComponent
        title="Bánh Trung thu | Savor Cake"
        description="Lựa chọn các mẫu bánh Trung thu từ truyền thống đến hiện đại, làm từ nguyên liệu chất lượng. Chiết khấu từ 10-25% khi mua sỉ"
        keywords="bánh trung thu, bánh nướng, trung thu 2024"
        altUrl={`${siteUrl}/banh-trung-thu`}
      />
    </TailwindLayout>
  )
}
