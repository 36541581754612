import React from 'react'
import 'twin.macro'
import MooncakesSection from '../MooncakeSection'
import BannerHeading from '../BannerHeading'
import { Left1 } from '../SubBgImg'
import useMediaQuery from '../../../hooks/useMediaQuery'

export default function MooncakeMenu() {
  const isDesktop = useMediaQuery('(min-width: 1024px')
  let leftStyle = { display: 'none' }
  if (isDesktop) {
    leftStyle = { top: '30%' }
  }
  return (
    <div tw="bg-sc_yellow-200 relative">
      <Left1 customStyles={leftStyle} />
      <BannerHeading
        textVariant={'moon24'}
        groupTitle={'Menu Bánh trung thu'}
      />
      <MooncakesSection />
    </div>
  )
}
