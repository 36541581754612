import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'

import BannerHeading from '../BannerHeading'
import { noPaddingContainerStyle } from '../../Base'

const agentFeats = [
  {
    id: 1,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/discountGreen.png"
        alt="discount"
      />
    ),
    content: 'chiết khấu đến 40%',
  },
  {
    id: 2,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/vid.png"
        alt="hỗ trợ vid, hình ảnh"
      />
    ),
    content: 'hỗ trợ video, hình ảnh',
  },
  {
    id: 3,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/badge.png"
        alt="thương hiệu uy tín, đảm bảo giấy tờ vsattp"
      />
    ),
    content: 'thương hiệu uy tín, đảm bảo giấy tờ vsattp',
  },
]

const FeatBlock = ({ feat }) => {
  return (
    <div tw="relative inline-flex z-0 w-full md:w-4/5 mx-auto">
      <div
        tw="rounded-full border-2 border-savor-cake-primary-green-avocado-200 w-24 md:w-28 lg:w-32 flex justify-center items-center relative !z-30 bg-sc_yellow-200"
        style={{ aspectRatio: 1 }}
      >
        <div tw="w-[60%] my-auto mx-auto">{feat.icon}</div>
      </div>
      <div
        tw="w-full  flex flex-col justify-center items-center relative -left-3 z-10 h-[50%] md:h-[60%] my-auto
      text-sc_yellow-300 font-bold text-base sm:text-lg lg:text-xl bg-savor-cake-primary-green-avocado-200 rounded-r-xl uppercase"
        css={[feat.id !== 3 ? tw`p-2` : tw`xs:py-0.5 sm:py-5 lg:py-6`]}
      >
        <p tw="w-5/6 text-center">{feat.content}</p>
      </div>
    </div>
  )
}

export default function AgentAndCollab() {
  return (
    <div tw="bg-sc_yellow-200 relative px-2">
      <BannerHeading
        textVariant={'moon24'}
        groupTitle={'Tuyển đại lý, CTV bán hàng'}
      />
      <div
        css={noPaddingContainerStyle}
        tw="font-barlow-sans-serif flex flex-col md:flex-row relative z-10"
        style={{ flexBasis: '50%' }}
      >
        {/* dùng basis-1/2 bị lỗi not found */}
        <div tw="flex flex-col gap-2 w-full sm:w-1/2 mx-auto items-start justify-center">
          {agentFeats.map((feat, index) => {
            return <FeatBlock key={index} feat={feat}></FeatBlock>
          })}
        </div>
        <div tw="w-3/4 md:w-1/2 mx-auto">
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/side-images/agent-ctv.png"
            alt="ảnh ctv"
          />
        </div>
      </div>
    </div>
  )
}
