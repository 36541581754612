import * as React from 'react'
import { map } from 'lodash'
import 'twin.macro'
import Slider from 'react-slick'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import ImageZoom from 'react-medium-image-zoom'

import { revealVariants } from '../../utils/motion'
import * as styles from '../BanhSinhNhatPage/Styles/FeedbackSlider.module.scss'
import { containerStyle } from '../Base'
import BannerHeading from './BannerHeading'
import { BotRight } from './SubBgImg'

export default function FeedbackSection() {
  const settings = {
    className: styles.slider,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }

  const pngImages = [
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/feedback/fb-banhtrungthu/fb1-nam-khoai-full-pack.jpg"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/feedback/fb-banhtrungthu/fb1.jpg"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/feedback/fb-banhtrungthu/fb3-tc.jpg"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/images/feedback/fb-banhtrungthu/fb4-trung.jpg"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-1.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-2.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-3.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-4.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-5.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-7.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-8.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-9.png"
          alt="Ảnh feedback"
        />
      ),
    },
    {
      image: (
        <StaticImage
          src="../../assets/images/feedback-images/feedback-btt-10.png"
          alt="Ảnh feedback"
        />
      ),
    },
  ]
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <div tw="mx-auto bg-sc_yellow-200 overflow-hidden relative" id="feedback">
        <div tw="hidden md:block">
          <BotRight customStyles={{}} />
        </div>
        <BannerHeading
          groupTitle={'Feedback khách hàng'}
          textVariant={'moon24'}
        />
        <p
          tw="text-center text-savor-cakes-grey-600 font-savor-cake-primary-lexend-deca
          text-lg lg:text-xl mb-0"
        >
          Cùng xem feedback của khách yêu về hương vị bánh trung thu của Savor
          Cake nhé!
        </p>

        <div css={containerStyle} tw="lg:max-w-screen-xl! px-6 py-2">
          <Slider {...settings}>
            {map(pngImages, (item, index) => {
              return (
                <div key={index}>
                  <ImageZoom>{item.image}</ImageZoom>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </motion.div>
  )
}
