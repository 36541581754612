import React from 'react'
import 'twin.macro'

import {
  // Heading2,
  // Line,
  // Description,
  ProductSection,
} from '../BanhSinhNhatPage/Base'
import MooncakeGroups from './MooncakeGroups'
import { mooncakes } from '../../utils/mooncakes'
import { containerStyle } from '../Base'

export default function MooncakesSection() {
  return (
    <div css={containerStyle} id="mooncake-collection" tw="overflow-hidden!">
      <ProductSection vertical textAlign="center">
        {/* <Line tw="mb-6 bg-savor-cake-primary-yellow-banana-400" center={true} />
        <Heading2 tw="text-savor-cake-primary-yellow-banana-400 uppercase">
          hương sắc mùa thu
        </Heading2> */}
        {/* <Description tw="text-savor-cake-primary-beige-300 lg:w-1/2 lg:mx-auto">
          Bộ sưu tập các vị nhân handmade ít ngọt. Lựa chọn ngay nhé!
        </Description> */}
        <MooncakeGroups mooncakes={mooncakes} />
      </ProductSection>
    </div>
  )
}
