import React from 'react'
import tw from 'twin.macro'
import { StaticImage } from 'gatsby-plugin-image'
import { noPaddingContainerStyle } from '../Base'

const TEXT_VARIANTS = {
  white: tw`text-white`,
  moon24: tw`text-sc_yellow-200 font-barlow-sans-serif font-extrabold text-xl xs:text-2xl sm:text-3xl md:text-4xl lg:text-5xl`,
}
const POSITION_VARIANTS = {
  center: tw`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`,
}

const TitleHeading = ({ groupTitle, textVariant = 'white' }) => {
  return (
    <h2 css={[tw`w-full uppercase text-center`, TEXT_VARIANTS[textVariant]]}>
      {groupTitle}
    </h2>
  )
}

const defaultBannerImage = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/heading.png"
    alt="Ảnh banner"
    placeholder="blurred"
  />
)
const BannerHeading = ({
  bannerImage,
  leftBannerIcon,
  rightBannerIcon,
  groupTitle,
  textVariant,
  positionVariant = 'center',
}) => {
  return (
    <div
      tw="relative !w-full md:!w-4/5 lg:!w-3/4 mx-auto py-8"
      css={noPaddingContainerStyle}
    >
      {bannerImage || defaultBannerImage}
      <div css={[tw`absolute w-full z-10`, POSITION_VARIANTS[positionVariant]]}>
        <TitleHeading groupTitle={groupTitle} textVariant={textVariant} />
      </div>
    </div>
  )
}

export default BannerHeading
