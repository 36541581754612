import tw from 'twin.macro'
import { map } from 'lodash'
import * as React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { Link as GatsbyLink } from 'gatsby'
import { useEffect, useRef } from 'react'
import { Icon } from 'semantic-ui-react'
import { StaticImage } from 'gatsby-plugin-image'

export default function TopMenu() {
  const [showMenu, setShowMenu] = React.useState(false)
  const menuItems = [
    { label: 'Giới thiệu', id: 'gioithieu' },
    { label: 'Bộ sưu tập', id: 'mooncake-collection' },
    { label: 'Chiết khấu', id: 'discount-moon' },
    { label: 'Feedback', id: 'feedback' },
    { label: 'Tư vấn', url: 'https://m.me/savor.vn' },
  ]

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowMenu(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <div
      tw="grid grid-cols-3 pt-8 px-4 sm:px-8 
    lg:grid-cols-12 lg:px-20"
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        tabIndex="-1"
        role="button"
        tw="order-first text-xl lg:hidden self-center"
        onClick={() => {
          setShowMenu(!showMenu)
        }}
      >
        <Icon
          className="large"
          tw="text-sc_yellow-400"
          name={showMenu ? 'close' : 'bars'}
        ></Icon>
      </div>
      <div tw="justify-self-center">
        <GatsbyLink to="/">
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/logos/savor-cake-logo-beige.png"
            alt="Logo"
            width={60}
          ></StaticImage>
        </GatsbyLink>
      </div>
      {/* mobi */}
      <ul
        tw="lg:hidden flex flex-col gap-10 align-bottom col-start-1 row-start-2"
        css={[
          showMenu
            ? tw`block text-sc_yellow-400 pl-2 text-lg font-thin z-10`
            : tw`hidden`,
        ]}
      >
        {map(menuItems, (item, index) => {
          return (
            <li
              key={index}
              css={[
                itemListStyle,
                index === menuItems.length - 1 && tw`border-b`,
              ]}
              tw="w-full border-t border-savor-cake-primary-green-avocado-0 
            border-collapse!"
            >
              {item.id ? (
                <ScrollLink
                  spy={true}
                  to={item.id}
                  smooth={true}
                  css={itemMobiClickStyle}
                >
                  {item.label}
                </ScrollLink>
              ) : (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={itemMobiClickStyle}
                >
                  {item.label}
                </a>
              )}
            </li>
          )
        })}
      </ul>
      {/* desktop */}
      <ul
        tw="hidden lg:grid lg:col-start-3 lg:col-end-10 lg:grid-cols-5
        items-center"
      >
        {map(menuItems, (item, index) => (
          <li css={itemListStyle} key={index}>
            {item.id ? (
              <ScrollLink to={item.id} smooth={true} css={itemDeskClickStyle}>
                {item.label}
              </ScrollLink>
            ) : (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                css={itemMobiClickStyle}
              >
                {item.label}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

const itemMobiClickStyle = tw`py-2 px-2 font-savor-cake-primary-lexend-deca cursor-pointer leading-tight
hover:text-savor-cake-secondary-red-strawberry-0
text-sc_yellow-400 pl-3 w-full block`

const itemDeskClickStyle = tw`hover:text-savor-cake-secondary-red-strawberry-0
text-sc_yellow-400
cursor-pointer`

const itemListStyle = tw`justify-self-center text-center text-base font-savor-cake-primary-lexend-deca 
font-semibold text-sc_yellow-400! xl:text-xl 2xl:text-2xl`
