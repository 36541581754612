import React from 'react'
import 'twin.macro'
import BannerHeading from '../../BannerHeading'
import MooncakeWrapper from '../../MooncakeWrapper'
import CakeSetGroups from './CakeSetGroups'

export default function CakeSetMenu() {
  return (
    <MooncakeWrapper>
      <BannerHeading
        textVariant={'moon24'}
        groupTitle={'Set quà tặng doanh nghiệp'}
      />
      <CakeSetGroups />
    </MooncakeWrapper>
  )
}
