import React from 'react'
import tw from 'twin.macro'
import BannerHeading from '../BannerHeading'
import { noPaddingContainerStyle } from '../../Base'
import { StaticImage } from 'gatsby-plugin-image'
import { motion } from 'framer-motion'
import { Link } from 'react-scroll'
import { buttonVariants } from '../../../utils/motion'
import { Left2, TopRight } from '../SubBgImg'

const features = [
  {
    id: 1,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/discountRed.png"
        alt="Chiết khẩu lớn đến 40%"
        style={{ objectFit: 'contain' }}
      />
    ),
    content: 'Chiết khẩu lớn đến 40%',
  },
  {
    id: 2,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/vat.png"
        alt="Xuất hóa đơn VAT"
        style={{ objectFit: 'contain' }}
      />
    ),
    content: 'Có hóa đơn VAT, giấy tờ VSATTP',
  },
  {
    id: 3,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/logo.png"
        alt="Hỗ trợ in logo doanh nghiệp"
        style={{ objectFit: 'contain' }}
      />
    ),
    content: 'Hỗ trợ in logo doanh nghiệp',
  },
  {
    id: 4,
    icon: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/ship.png"
        alt="Vận chuyển toàn quốc"
        style={{ objectFit: 'contain' }}
      />
    ),
    content: 'Vận chuyển toàn quốc',
  },
]
const sideImg = (
  <StaticImage
    src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/side-images/busiSubBg.png"
    alt="side img"
  />
)
export default function BusinessFeats() {
  let styleLeft2 = { bottom: 0, top: null, width: '15%' }
  return (
    <>
      <div tw="bg-savor-cake-primary-green-avocado-300 w-full h-8"></div>
      <section tw="bg-sc_yellow-200 relative">
        <div tw="hidden sm:block">
          <TopRight customStyles={{ width: '9%' }} />
        </div>
        <div tw="hidden sm:block">
          <Left2 customStyles={styleLeft2} />
        </div>
        <BannerHeading
          textVariant={'moon24'}
          groupTitle={'Ưu đãi doanh nghiệp'}
        />
        {/* container */}
        <div
          css={[
            noPaddingContainerStyle,
            tw`font-barlow-sans-serif flex flex-col-reverse md:flex-row gap-4 px-2`,
          ]}
        >
          <div
            className="full-static-image"
            tw="w-full h-auto md:w-1/2 mx-auto my-auto px-4"
          >
            <StaticImage
              src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/products/2024/set-4-cao-cap-kim-long.jpg"
              alt="set 4 bánh trung thu cao cấp Kim Long"
            />
          </div>
          {/* feats box*/}
          <div tw="relative w-full md:w-1/2 object-contain my-auto">
            {sideImg}
            <div tw="flex flex-wrap justify-center items-stretch z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-2 gap-3 sm:gap-4">
              {features.map((feat, index) => {
                return (
                  <div
                    tw="flex flex-col items-stretch justify-center w-[38%] sm:w-[40%] gap-3"
                    key={index}
                  >
                    <div
                      tw="bg-linear-gradient-yellow-200 rounded-full w-full flex flex-col justify-center items-center"
                      style={{ aspectRatio: 1 }}
                    >
                      <div tw="w-1/2 flex justify-center items-center">
                        {feat.icon}
                      </div>
                    </div>
                    <p tw="text-center font-bold text-lg sm:text-xl text-sc_red-100">
                      {feat.content}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* button */}
        <OrderButton />
      </section>
    </>
  )
}

const OrderButton = () => {
  return (
    <div tw="flex justify-center w-full">
      <motion.div variants={buttonVariants} whileHover="hover">
        <Link smooth={true} to="mooncake-set" href="#mooncake-set">
          {/* cần href ở Link (thực chất là thẻ a) để tăng điểm SEO cho tiêu chí Link are crawlable */}
          <button
            tw="
              w-60 h-12 md:w-80 lg:w-96 lg:h-14
            text-sc_yellow-200 text-lg xs:text-xl sm:text-2xl lg:text-3xl 2xl:text-4xl
              rounded-md border-0 bg-savor-cake-primary-green-avocado-200
              m-auto
              font-barlow-sans-serif font-bold
              mb-10 mt-8
              lg:col-span-6 lg:row-span-1 lg:col-start-1
              cursor-pointer
              uppercase
            "
          >
            đặt hàng ngay
          </button>
        </Link>
      </motion.div>
    </div>
  )
}
