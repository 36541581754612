import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { StaticImgAbsoluteStyle } from '../../Base'

export const BotRight = ({ isDesktop, customStyles }) => {
  return (
    <>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/botRight.png"
        alt="background image"
        placeholder="blurred"
        style={{
          ...StaticImgAbsoluteStyle,
          right: 0,
          bottom: 0,
          width: '10%',
          ...customStyles,
        }}
      />
    </>
  )
}

export const TopRight = ({ isDesktop, customStyles }) => {
  return (
    <>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/topRight.png"
        alt="background image"
        placeholder="blurred"
        style={{
          ...StaticImgAbsoluteStyle,
          right: 0,
          top: 0,
          width: '10%',
          ...customStyles,
        }}
      />
    </>
  )
}
export const Left1 = ({ isDesktop, customStyles }) => {
  return (
    <>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/left1.png"
        alt="background image"
        placeholder="blurred"
        style={{
          ...StaticImgAbsoluteStyle,
          left: 0,
          top: 0,
          width: '10%',
          ...customStyles,
        }}
      />
    </>
  )
}
export const Left2 = ({ isDesktop, customStyles }) => {
  return (
    <>
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/icons/left2.png"
        alt="background image"
        placeholder="blurred"
        style={{
          ...StaticImgAbsoluteStyle,
          left: 0,
          top: 0,
          width: '10%',
          ...customStyles,
        }}
      />
    </>
  )
}
