import React, { useContext } from 'react'
import 'twin.macro'
import { map } from 'lodash'
import { Button, Icon, Header, Modal } from 'semantic-ui-react'
// import ImageZoom from 'react-medium-image-zoom'
import { useAtom } from 'jotai'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { ImageDataContext } from '../../../states/imageDataContext'
import {
  selectedProductAtom,
  selectedProductIdAtom,
} from '../../../states/selectedProduct'
import {
  getImageFromNodeFile,
  getImageNodesByProduct,
} from '../../../utils/utils'
import { modalViewProductVisibleAtom } from '../../../states/modal.state'

export default function ModalViewProduct({ nodeName }) {
  const [modalViewProductVisible, setModalViewProductVisible] = useAtom(
    modalViewProductVisibleAtom
  )
  const [selectedProduct] = useAtom(selectedProductAtom)
  const [, setSelectedProductId] = useAtom(selectedProductIdAtom)
  const data = useContext(ImageDataContext)

  let relatedImages = []
  if (selectedProduct) {
    relatedImages = getImageNodesByProduct(data, nodeName, selectedProduct.id)
  }

  return (
    <Modal
      closeIcon
      basic
      open={!!selectedProduct && modalViewProductVisible}
      onClose={() => {
        setSelectedProductId(null)
        setModalViewProductVisible(false)
      }}
    >
      {selectedProduct ? (
        <>
          <Modal.Content>
            <div tw="w-full h-2"></div>
            <Header inverted>{selectedProduct.description}</Header>
            <p tw="mb-2">{selectedProduct.content}</p>
            <div tw="flex flex-col items-center gap-2">
              {selectedProduct.image}
              {map(relatedImages, (file) => {
                const { imagePath, imageName } = getImageFromNodeFile(
                  file,
                  nodeName
                )
                const img = getImage(imagePath)
                return (
                  // <ImageZoom key={imageName}>
                  <GatsbyImage image={img} alt={imageName} />
                  // </ImageZoom>
                )
              })}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="red"
              inverted
              onClick={() => setSelectedProductId(null)}
            >
              <Icon name="remove" /> Đóng
            </Button>
          </Modal.Actions>
          <div tw="w-full h-22"></div>
        </>
      ) : null}
    </Modal>
  )
}
