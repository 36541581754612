import 'twin.macro'
import * as React from 'react'
import { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useAnimation, motion } from 'framer-motion'
import { revealVariants } from '../../../utils/motion'
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { StaticBgImageStyle } from '../../Base'
import TopMenu from '../TopMenu'
import { SectionWrapper } from '../../BanhSinhNhatPage/Base'

export default function HeroSection({ refMap }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
      id="scake-savor-cake-main-hero"
    >
      <HeroSectionWrapper isDesktop={isDesktop}>
        <TopMenu refMap={refMap} />

        {/* hero container */}
        <div tw="w-full mx-auto p-0 lg:pb-0 xl:pb-2 2xl:py-0 lg:px-4 xl:px-10 2xl:px-32">
          {/* main content */}
          <div tw="flex flex-col lg:grid lg:grid-cols-14 mx-auto h-full">
            {/* heading */}
            <div tw="lg:col-start-1 lg:col-span-5 mt-10 lg:mt-24 mx-4 sm:mx-12 lg:mx-0 lg:ml-8">
              {/* Header */}
              <HeroHeading isDesktop={isDesktop} />

              {/* Order button on large screen */}
              {/* <div tw="hidden lg:block lg:w-[115%]">
                <OrderButton />
              </div> */}
            </div>

            {/* cake image */}
            <HeroCakes isDesktop={isDesktop} />

            {/* <div tw="lg:hidden">
              <OrderButton />
            </div> */}
          </div>
        </div>
      </HeroSectionWrapper>
    </motion.div>
  )
}

const HeroSectionWrapper = ({ children, isDesktop }) => {
  return (
    <SectionWrapper>
      {isDesktop && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/hero-section/2024/heroBg.jpg"
          alt="Background Image"
          style={{
            ...StaticBgImageStyle,
          }}
          loading="eager"
          quality={100}
          placeholder="blurred"
        />
      )}
      {!isDesktop && (
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/hero-section/2024/heroBgMob.jpg"
          alt="Background Image"
          style={StaticBgImageStyle}
          loading="eager"
          quality={100}
          placeholder="blurred"
        />
      )}
      {children}
    </SectionWrapper>
  )
}
const HeroHeading = ({ isDesktop }) => {
  return (
    <div tw="w-11/12 md:w-4/5 lg:w-[115%] mx-auto mb-3">
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/hero-section/2024/head.png"
        alt="Heading Hương vị mùa hè"
        placeholder="blurred"
      />
    </div>
  )
}

const HeroCakes = ({ isDesktop }) => {
  return (
    <div
      tw="lg:col-span-9 lg:col-start-6 m-auto lg:mt-6 w-full lg:h-full lg:flex lg:flex-col lg:justify-start mt-4 mb-10 lg:mb-8 h-full pr-4 xs:pr-0
      "
    >
      <div tw="lg:mx-auto relative lg:ml-10 lg:pb-0 h-auto">
        <StaticImage
          src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-btt/hero-section/2024/cakes.png"
          alt="Heading"
          placeholder="blurred"
        />
      </div>
    </div>
  )
}
